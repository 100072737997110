
import Vue, { PropType } from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";

import { Survey } from "@/interfaces";

export default Vue.extend({
  components: {
    MyFormattedDate
  },
  props: {
    survey: {
      type: Object as PropType<Survey>,
      required: true
    }
  }
});

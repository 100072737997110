
import Vue from "vue";

import MyCard from "@/components/survey/MyCard.vue";

import { Survey } from "@/interfaces";

import http from "@/http";

export interface DataType {
  survey?: Survey;
}

export default Vue.extend({
  components: {
    MyCard
  },
  data(): DataType {
    return {
      survey: undefined
    };
  },
  computed: {
    breadcrumbs() {
      let text = "";
      if (this.survey) {
        text = `# ${this.survey.cancel.id} ${this.survey.cancel.room.building.name}
      ${this.survey.cancel.room.display_number}`;
      }
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "アンケート",
          disabled: false,
          to: "/surveys"
        },
        {
          text,
          disabled: true
        }
      ];
    }
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `/surveys/${id}`;

    const { data } = await http.get<Survey>(url);

    this.survey = data;
  }
});

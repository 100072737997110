
import Vue, { PropType } from "vue";

import dayjs from "dayjs";

export default Vue.extend({
  props: {
    datetime: {
      type: String,
      required: true
    }
  },
  computed: {
    dateString(): string {
      return dayjs(this.datetime).format("YYYY-MM-DD");
    }
  }
});

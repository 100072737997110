
import Vue, { PropType } from "vue";

import MyOverview from "@/components/survey/MyOverview.vue";

import { Survey } from "@/interfaces";

import { mdiStar, mdiStarOutline } from "@mdi/js";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    survey: {
      type: Object as PropType<Survey>,
      required: true
    }
  },
  computed: {
    icon() {
      return { mdiStar, mdiStarOutline };
    }
  }
});
